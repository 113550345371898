@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300..900&family=Hurricane&family=Odibee+Sans&display=swap');

:root {
  --background-color:white;
  --text-color: #787878;
  --titles-color: #0E0E0E;
  --hover-color: #F7A75E;
  --overlay: rgba(0,0,0,.8);
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html, body{
  background:var(--background-color);
}

body{
  font-family: "Darker Grotesque", sans-serif;
  font-optical-sizing: auto;
  font-weight:300;
  font-style: normal;
  font-size: 14px;
  color: var(--text-color);
  overflow: hidden;
}

.App {
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}


.main-container{
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}


.titles{
  font-family: "Hurricane", cursive;
  font-weight: 400;
  font-style: normal;
}

.subtitle{
  font-family: "Hurricane", cursive;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 2px;
}

.subtitle2{
  font-family: "Darker Grotesque", sans-serif;
  font-weight: 400;
  color: #787878;
}


@media screen and (min-width: 17.5em){
  .titles{
    font-size: 5rem;
  }
  .subtitle{
    font-size: 4rem;
  }
  .subtitle2{
    font-size: 25px;
  }
}

@media screen and (min-width: 33.125em){
  .titles{
    font-size: 8rem;
  }
  .subtitle{
    font-size: 4rem;
  }
  .subtitle2{
    font-size: 30px;
  }
}

@media screen and (min-width: 60em){
  .titles{
    font-size: 8rem;
  }
  .subtitle{
    font-size: 3rem;
  }
  .subtitle2{
    font-size: 30px;
  }
}

@media screen and (min-width: 80em){
  .titles{
    font-size: 10rem;
  }
  .subtitle{
    font-size: 3rem;
  }
  .subtitle2{
    font-size: 30px;
  }
}