.hero{
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
  }
    
  video{
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
  }
  
  .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(255,255,255,.9);
  }
  
  
  .texture-geo{
    position: absolute;
    background: url("../assets/texture_geo.png") no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100vh;
    opacity: 0.6;
  }
  
  
  .content{
      position: absolute;
      width: 100%;
      height: 63vh;
      display: flex;
      flex-direction: column;
      justify-content: baseline;
      align-items: center;
      color: black;
      z-index: 4;
  }
  
  @media screen and (min-width: 17.5em){
    .content{
        height: 100vh;
        top: 15rem;
    }

  }
  
  @media screen and (min-width: 33.125em){
    .content{
        height: 100vh;
        top: 25rem;
    }

  }

  @media screen and (min-width: 60em){
    .content{
        height: 100vh;
        top: 15rem;
    }

  }

  @media screen and (min-width: 80em){
    .content{
        height: 100vh;
        top: 25rem;
    }

  }